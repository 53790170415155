<template>
    <div class="eCommerce-container">
        <div class="btn-box">
            <el-button type="primary" @click="exportBtn" plain>导出数据</el-button>
        </div>
        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column prop="nick_name" label="客户名称" align="center"></el-table-column>
            <el-table-column prop="transactions_num" label="交易笔数" align="center"></el-table-column>
            <!-- <el-table-column prop="transactions_amount" label="交易总额" align="center"></el-table-column> -->
            <el-table-column prop="transactions_amount" label="客单价（元）" align="center"></el-table-column>
            <el-table-column prop="avg_price" label="平均客单价（元）" align="center"></el-table-column>
            <el-table-column prop="lately_transactions_time" label="上次交易时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="seeBtn(scope.row.id)">查看</el-link>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0 30px" v-if="infoList.length > 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { stuOpStuOpCustomerInfo, stuOpExportCustomerInfo } from '@/utils/apis.js'
    export default {
        data() {
            return {
                op_id: Number(this.$route.query.op_id) || null,
                course_id: Number(this.$route.query.course_id) || null,
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                isExamOrTrain: localStorage.getItem('examId'), // 有考试， 没有训练
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                if (this.op_id) {
                    params.op_id = this.op_id
                    params.course_id = this.course_id
                }
                stuOpStuOpCustomerInfo(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            // 导出数据
            exportBtn() {
                let params = {}
                if (this.op_id) {
                    params.op_id = this.op_id
                    params.course_id = this.course_id
                }
                stuOpExportCustomerInfo(params).then((res) => {
                    window.location.href = res.data;
                })
            },
            // 查看
            seeBtn(id) {
                if (this.isExamOrTrain) {
                    let url = this.$router.resolve({
                        path: '/student/examing/ECommerceCollectClean/customerInfoDetail',
                        query: {
                            id: id
                        }
                    });
                    window.open(url.href);
                } else {
                    let url = this.$router.resolve({
                        path: '/student/trainCenter/ECommerceDataCollect/customerInfoDetail',
                        query: {
                            id: id,
                            op_id: this.op_id,
                            course_id: this.course_id,
                        }
                    })
                    window.open(url.href);
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .btn-box {
        margin-top: 20px;
        text-align: right;
    }
</style>